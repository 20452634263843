import ManageInspectionHome from './views/Home.vue'
import ManageInspection from './views/Inspection.vue'
import ManageInspectionCall from './views/InspectionCall.vue'
import ManageInspectionCallImport from './views/InspectionCallImport.vue'
import ManageInspectionCreate from './views/InspectionCreate.vue'
import ManageInspectionDetail from './views/InspectionDetail.vue'
import ManageInspectionDuplicate from './views/InspectionDuplicate.vue'
import ManageInspectionEdit from './views/InspectionEdit.vue'
import ManageInspectionResult from './views/InspectionResult.vue'
import ManageInspectionResultDetail from './views/InspectionResultDetail.vue'
import ManageInspectionResultInterrupted from './views/InspectionResultInterrupted.vue'
import ManageInspectionResultInterruptedDetail from './views/InspectionResultInterruptedDetail.vue'
import ManageInspectionType from './views/InspectionType.vue'

export default {
  routes: [
    {
      path: '/manage/inspection',
      component: ManageInspectionHome,
      name: 'ManageInspectionHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'inspections',
          component: ManageInspection,
          name: 'ManageInspection',
        },
        {
          path: 'inspections/:id([0-9]+)',
          component: ManageInspectionDetail,
          name: 'ManageInspectionDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'inspections/create',
          component: ManageInspectionCreate,
          name: 'ManageInspectionCreate',
        },
        {
          path: 'inspections/edit/:id([0-9]+)',
          component: ManageInspectionEdit,
          name: 'ManageInspectionEdit',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'inspections/duplicate',
          component: ManageInspectionDuplicate,
          name: 'ManageInspectionDuplicate',
        },
        {
          path: 'inspection_calls',
          component: ManageInspectionCall,
          name: 'ManageInspectionCall',
        },
        {
          path: 'inspection_calls/import',
          component: ManageInspectionCallImport,
          name: 'ManageInspectionCallImport',
        },
        {
          path: 'results',
          component: ManageInspectionResult,
          name: 'ManageInspectionResult',
        },
        {
          path: 'results/:id([0-9a-zA-Z]{26})',
          component: ManageInspectionResultDetail,
          name: 'ManageInspectionResultDetail',
          props: (route) => ({ id: route.params.id }),
        },
        {
          path: 'results/interrupted',
          component: ManageInspectionResultInterrupted,
          name: 'ManageInspectionResultInterrupted',
        },
        {
          path: 'results/interrupted/:id([0-9a-zA-Z]{26})',
          component: ManageInspectionResultInterruptedDetail,
          name: 'ManageInspectionResultInterruptedDetail',
          props: (route) => ({ id: route.params.id }),
        },
        {
          path: 'type',
          component: ManageInspectionType,
          name: 'ManageInspectionType',
        },
      ],
    },
  ],
}
