<template>
  <div class="card mb-3">
    <h5 class="card-header bg-secondary">製品検査</h5>
    <div class="card-body">
      <div class="row row-cols-1 row-cols-md-2 g-1">
        <div class="col">
          <router-link
            :to="{
              name: 'ManageInspectionPlan',
            }"
            class="btn btn-lg btn-secondary w-100 py-3"
            ><i class="bi bi-clipboard-check d-block"></i>検査指示</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManageInspectionResult',
            }"
            class="btn btn-lg btn-secondary w-100 py-3"
            ><i class="bi bi-clipboard-data d-block"></i>検査結果</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManageInspection',
            }"
            class="btn btn-lg btn-outline-secondary w-100 py-3"
            >検査シート</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManageInspectionType',
            }"
            class="btn btn-lg btn-outline-secondary w-100 py-3"
            >検査種別</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManageInspectionCall',
            }"
            class="btn btn-lg btn-outline-secondary w-100 py-3"
            >型式・型番</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManageInspectionResultInterrupted',
            }"
            class="btn btn-lg btn-outline-warning w-100 py-3"
            >検査中断中</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuInspection',
}
</script>
