<template>
  <Navbar>
    <template v-slot:navbarTitle>製品検査</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="clearfix mb-3">
      <div class="float-end">
        <router-link
          :to="{
            name: 'ManageInspectionResultInterrupted',
          }"
          class="btn btn-outline-dark"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-left"></i
          ></span>
          <span class="d-none d-sm-block">一覧に戻る</span>
        </router-link>
      </div>
      <h5><i class="bi bi-clipboard-data me-1"></i>検査結果詳細</h5>
    </div>

    <h5>
      検査情報
      <a
        class="fs-6"
        data-bs-toggle="collapse"
        href="#resultData"
        role="button"
        aria-expanded="true"
        aria-controls="resultData"
        ><i class="bi bi-arrows-collapse"></i>
      </a>
    </h5>

    <div class="collapse show" id="resultData">
      <table class="table table-bordered table-row bg-white mb-3">
        <tbody>
          <tr>
            <th class="col-sm-4 bg-light">検査番号</th>
            <td class="col-sm-8">{{ result.inspection_result_number }}</td>
          </tr>
          <tr>
            <th class="bg-light">検査種別</th>
            <td>{{ result.inspection_type_name }}</td>
          </tr>
          <tr>
            <th class="bg-light">型式</th>
            <td>{{ result.product_model }}</td>
          </tr>
          <tr>
            <th class="bg-light">機種コード</th>
            <td>{{ result.product_code }}</td>
          </tr>
          <tr>
            <th class="bg-light">客先製品コード</th>
            <td>{{ result.client_product_code }}</td>
          </tr>
          <tr>
            <th class="bg-light">検査シート</th>
            <td>{{ result.inspection_name }}</td>
          </tr>
          <tr>
            <th class="bg-light">検査員</th>
            <td>{{ result.worker }}</td>
          </tr>
          <tr>
            <th class="bg-light">検査開始日時</th>
            <td>{{ result.begin_datetime }}</td>
          </tr>
          <tr>
            <th class="bg-light">検査終了日時</th>
            <td>{{ result.end_datetime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr />

    <!-- result detail -->
    <h5>検査結果</h5>
    <div v-if="result.details && result.details.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-1">項目種別</th>
              <th scope="col" class="col-sm-2">項目名</th>
              <th scope="col" class="col-sm-4">検査結果</th>
              <th scope="col" class="col-sm-1">写真</th>
              <th scope="col" class="col-sm-2">項目検査員</th>
              <th scope="col" class="col-sm-2">検査日時</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="detail in result.details" :key="detail.id">
              <td class="text-center">{{ detail.inspection_item_type }}</td>
              <td class="text-center">{{ detail.inspection_item_name }}</td>
              <td>
                <dl class="row mb-0">
                  <dt class="col-sm-1 mb-0">
                    <ActiveStatus :is_active="detail.is_success" />
                  </dt>
                  <dd class="col-sm-11 mb-0">
                    <span
                      v-html="replaceNewlinesWithBr(detail.description)"
                      class="text-wrap"
                    ></span>
                  </dd>
                </dl>
              </td>
              <td class="text-center">
                <span v-if="detail.photo">
                  <i @click="showImage(detail.photo)" class="bi bi-image"></i>
                </span>
              </td>
              <td class="text-center">{{ detail.worker }}</td>
              <td class="text-end">{{ detail.inspection_result_date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <ImageModal ref="imageModal" />
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import ActiveStatus from '@/components/TheActiveStatus.vue'
import ImageModal from '@/components/TheImageModal.vue'
import { replaceNewlinesWithBr } from '@/utils/stringUtils'

export default {
  name: 'ManageInspectionResultInterruptedDetail',
  components: {
    Navbar,
    ActiveStatus,
    ImageModal,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    result() {
      return store.getters['inspection_result/detail']
    },
  },

  async created() {
    await this.getData(this.id).catch((error) => {
      if (error.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'ManageInspectionResultInterrupted',
          })
        }, this.$store.getters['alert/timeout'])
      }
    })
  },

  methods: {
    replaceNewlinesWithBr,
    getData(id) {
      return store.dispatch('inspection_result/fetchData', id)
    },
    showImage(url) {
      this.$refs.imageModal.showModal(url)
    },
  },
}
</script>
