<template>
  <navbar>
    <template v-slot:navbarTitle>製品検査</template>
  </navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0">
        <i class="bi bi-clipboard-check me-1"></i>検査指示一覧
      </h5>
      <div class="ms-auto">
        <router-link
          :to="{
            name: 'ManageInspectionPlanImport',
          }"
          class="btn btn-primary"
          ><i class="bi bi-cloud-upload me-2"></i
          >検査指示データをアップロードする</router-link
        >
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <div class="me-auto col-6 col-lg-4 col-xl-3">
        <search-form
          :query="query"
          query-key="inspection_plan_number"
          placeholder="指示番号で検索"
          :use-search-modal="true"
          @update-search-query="onUpdateSearchQuery"
          @open-search-modal="onOpenSearchModal"
        ></search-form>
      </div>
      <pagination :prev="prev" :next="next" @move-page="movePage"></pagination>
    </div>

    <!-- plans -->
    <div v-if="results && results.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-3">得意先名</th>
              <th scope="col" class="col-sm-1">受注番号</th>
              <th scope="col" class="col-sm-1">指示番号</th>
              <th scope="col" class="col-sm-1">納期</th>
              <th scope="col" class="col-sm-1">型式</th>
              <th scope="col" class="col-sm-1">機種コード</th>
              <th scope="col" class="col-sm-1">客先製品コード</th>
              <th scope="col" class="col-sm-1">指示数量</th>
              <th scope="col" class="col-sm-1">指示残数</th>
              <th scope="col" class="col-sm-1">取込日時</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="result in results" :key="result.id">
              <td>{{ result.client_name }}</td>
              <td class="text-center">{{ result.order_number }}</td>
              <td class="text-center">
                <router-link
                  :to="{
                    name: 'ManageInspectionPlanDetail',
                    params: { id: result.id },
                  }"
                  >{{ result.inspection_plan_number }}</router-link
                >
              </td>
              <td class="text-center">{{ result.order_deadline }}</td>
              <td>{{ result.product_model }}</td>
              <td class="text-center">{{ result.product_code }}</td>
              <td>{{ result.client_product_code }}</td>
              <td class="text-end">{{ result.number_unit }}</td>
              <td class="text-end">{{ result.number_remaining }}</td>
              <td class="text-end">{{ result.created_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'

export default {
  name: 'ManageInspectionPlan',
  inject: ['dayjs'],
  components: {
    Navbar,
    Pagination,
    SearchForm,
    SearchModal,
  },

  data() {
    return {
      query: {},
      today: this.dayjs().format('YYYY-MM-DD'),
      nextMonth: this.dayjs().add(1, 'month').format('YYYY-MM-DD'),
    }
  },

  computed: {
    results() {
      return store.getters['inspection_plan/results']
    },
    prev() {
      return store.getters['inspection_plan/prev']
    },
    next() {
      return store.getters['inspection_plan/next']
    },
    saveQuery() {
      return store.getters['inspection_plan/query']
    },
    searchItems() {
      return [
        {
          id: 'client_name',
          label: '得意先名（部分一致）',
          type: 'text',
        },
        {
          id: 'order_number',
          label: '受注番号（完全一致）',
          type: 'text',
        },
        {
          id: 'inspection_plan_number',
          label: '指示番号（部分一致）',
          type: 'text',
        },
        {
          id: 'date',
          label: '納期',
          type: 'date',
        },
        {
          id: 'product_model',
          label: '型式（部分一致）',
          type: 'text',
        },
        {
          id: 'product_code',
          label: '機種コード（完全一致）',
          type: 'text',
        },
        {
          id: 'client_product_code',
          label: '客先製品コード（前方一致）',
          type: 'text',
        },
      ]
    },
  },

  async created() {
    this.query = this.saveQuery
    if (Object.keys(this.query).length === 0) {
      this.query = { date_from: this.today, date_to: this.nextMonth }
    }
    await this.setQuery(this.query)
    await this.getData()
  },
  beforeRouteLeave(to, from, next) {
    // 詳細画面以外のページに移動する場合にデータをクリアする
    if (to.name !== 'ManageInspectionPlanDetail') {
      this.clearQuery()
      this.clearData()
    }
    next()
  },

  methods: {
    getData() {
      return store.dispatch('inspection_plan/fetchAll')
    },
    setQuery(query = {}) {
      store.commit('inspection_plan/setQuery', query)
    },
    clearQuery() {
      store.commit('inspection_plan/clearQuery')
    },
    clearData() {
      store.commit('inspection_plan/clearData')
    },
    async movePage(page) {
      this.query = Object.assign(this.query, { page: page })
      await this.setQuery(this.query)
      await this.getData()
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    async onUpdateSearchQuery(query = {}) {
      this.query = query
      await this.setQuery(this.query)
      await this.getData()
    },
  },
}
</script>
